import React, {useState} from "react";
import PropTypes from "prop-types";
import View from "components/inventory/admin/Form.view";
import { useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import swal from "sweetalert";

const Form = ({ inventoryId }) => {

    const [step, setStep] = useState(1);

    const [callSave, setCallSave] = usePost("/inventories/update_parts", {
        onCompleted: (data) => {
            console.log(data.value_response);
            if(data.value_response === 1){
                swal("¡Éxito!", "Pieza actualizada exitosamente.", "success").then(() => {
                    window.location.href = "/inventory";
                });
            } else if (data.value_response === 0){
                swal("¡Error!", "La cantidad de piezas totales no puede ser menor a las piezas en uso. ", "error");
            } else {
                swal("¡Error!", "Error al actualizar la pieza.", "error");
            }
        },
        onError: () => {
            swal("¡Error!", "Error al actualizar la pieza.", "error")
        }
    });

    const partDetails = useDetail(`{
        inventory{
            totalParts
            inUse
            category
            parts{
                name
                partId
                isManual
                craneModel
                inventoryExceptions{
                    status
                }
            }
        }
    }`, inventoryId);

    if (partDetails.loading) return <Loading />;
    if (partDetails.error) return "Error";

    const inventory = partDetails.data.inventory;

    const onSubmit = (values) => {

        delete values.id;

        values.inventory_id = inventoryId;
        callSave(values);
    };

    return <View inventory={inventory} step={step} onSubmit={onSubmit} />;
}

Form.propTypes = {
    inventoryId: PropTypes.number.isRequired
};

export default Form;