import React, { useState, useRef } from "react";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import View from "components/preventive_maintenances/PreventiveMaintenances.view";

const PreventiveMaintenances = () => {

    const pathname = window.location.pathname;
    const searchRef = useRef(null);
    const [search, setSearch] = useState("");
    const [searchBy, setSearchBy] = useState("Grua");
    const currentDate = new Date();

    const qPrevMaintenance = useQuery(`{
      preventiveMaintenances {
        zone
        status
        startDate
      }
    }`);

    const qMaintenances = useQuery(`{
      preventiveMaintenances {
        id
        zone
        status
        startDate
        technician{
          id
          firstName
          lastName
        }
      }
    }`);

    const qCranes = useQuery(`{
        cranes {
          series
          number
          model
        }
    }`, "in_use=true");

    const qOrders = useQuery(`{
        orders {
          buildingName
          clientName
          applicant {
            id
            firstName
            lastName
          }
        }
    }`);

    if(qCranes.loading) return <Loading/>;
    if(qCranes.error) return <Error/>;

    if(qOrders.loading) return <Loading/>;
    if(qOrders.error) return <Error/>;

    if(qMaintenances.loading) return <Loading/>;
    if(qMaintenances.error) return <Error/>;

    if(qPrevMaintenance.loading) return <Loading/>
    if(qPrevMaintenance.error) return <Error/>;

    const { cranes } = qCranes.data;
    const { orders } = qOrders.data;
    const { preventiveMaintenances } = qPrevMaintenance.data;

    const assignedCount = preventiveMaintenances.filter(
        (maintenance) => maintenance.status === "ASSIGNED"  ||  maintenance.status === "IN_PROGRESS"
    ).length;

    const expiredCount = preventiveMaintenances.filter(
      (maintenance) => 
          (new Date(maintenance.startDate) < currentDate) &&
          (maintenance.status === "ASSIGNED" || maintenance.status === "CREATED")
    ).length;

    const handleChange = (value) =>
        setSearch(value);

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
        handleChange("");
    }

    return (
        <View
            cranes={cranes}
            orders={orders}
            search={search}
            searchBy={searchBy}
            pathname={pathname}
            searchRef={searchRef}
            assignedCount={assignedCount}
            expiredCount={expiredCount}
            handleChange={handleChange}
            maintenances={qMaintenances.data.preventiveMaintenances}
            handleSearchByChange={handleSearchByChange}
        />
    );
}

PreventiveMaintenances.propTypes = {};

export default PreventiveMaintenances;
