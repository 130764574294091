import React, {useEffect,useState} from "react";
import swal from "sweetalert";
import { useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { useGetCall } from "seed/api";
import View from "components/preventive_maintenances/Validation.view";

function Validation() {

    const[isAuth, setIsAuth] = useState(false);

    const searchParams = new URLSearchParams(document.location.search)
    var maintenance_id = searchParams.get('maintenance_id')
    var type_link = searchParams.get('type_link')

    console.log(type_link)

    const [callPost, qPost] = usePost("/preventive_maintenances/validate_report", {
        onCompleted: () => { 
            swal("¡Listo!", "Reporte revisado, gracias por verificar la información.", "success").then(() => {
                window.location.reload();
            });
        },
        onError: (error) => { },
    });

    const qMaintenanceInfo = useDetail(
        `{
            preventiveMaintenance{
                status
                technician{
                    firstName
                    lastName
                }
                report{
                    supervisorCheck
                    residentCheck
                }
                signature{
                    url
                }
                operator{
                    user{
                        firstName
                        lastName
                    }
                }
                order{
                    buildingName
                    clientName
                    crane{
                        model
                        series
                        number
                    }    
                }
                maintenancePreventiveMaintenanceEvidences{
                    value
                    file{
                        url
                    }
                    category{
                        title
                        subtitle
                        typeQuestion
                    }
                }
            }
        }`,
        maintenance_id
    );

    if(searchParams.has("token") && searchParams.has("user_id")) {
        sessionStorage.setItem("token", searchParams.get("token"));
        sessionStorage.setItem("id", searchParams.get("user_id"));
    }

    const [callAuth, reqCall] = useGetCall("/auth/user", "", {
        onCompleted: (data) => setIsAuth(true),
        onError: () => {}
    });

    useEffect(() => {
        callAuth();
    }, []);

    if(!isAuth) return null;

    if(qMaintenanceInfo.loading) return <Loading />;
    if(qMaintenanceInfo.error) return "Error";

    const { preventiveMaintenance } = qMaintenanceInfo.data;

    const onSubmit = (values) => {
        const signatureDataUrl = values.signature ? values.signature.toDataURL() : null;
        if (type_link == "RESIDENT") {
            callPost(
                {
                    maintenance_id: maintenance_id,
                    resident_name: values.resident_name,
                    email_resident: values.email_resident,
                    signature: signatureDataUrl,
                    type_validation: "RESIDENT"
                }
            );
        } else {
            callPost(
                {
                    maintenance_id: maintenance_id,
                    type_validation: "SUPERVISOR"
                }
            );
        }
    }                                                                                                                                                                                                                   

    return <View 
                preventiveMaintenance={preventiveMaintenance} 
                type_link={type_link}
                onSubmit={onSubmit}
            />;

}

Validation.propTypes = {};

export default Validation;