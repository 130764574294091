import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const InventoryReportView = ({
    filteredData,
    activeTab,
    handleTabClick,
    orders,
    handleDelete
}) => (
    <div className="container mt-4 d-flex">
        <ul
            className="nav flex-column nav-pills mr-3"
            style={{
                width: '235px',
                maxHeight: '60vh',
                overflowY: 'auto',
                overflowX: 'hidden',
                display: 'block',
                padding: '0',
                listStyle: 'none',
            }}
        >
            {orders.map((order, index) => (
                <li className="nav-item" key={index}>
                    <div
                        onClick={() => handleTabClick(order.id)}
                        style={{
                            cursor: 'pointer',
                            width: '180px',
                            backgroundColor: activeTab === order.id ? "#007bff" : "#f8f9fa",
                            color: activeTab === order.id ? "#fff" : "#000",
                            textAlign: 'center',
                            padding: '10px',
                            fontWeight: activeTab === order.id ? "bold" : "normal",
                            marginBottom: '5px',
                            border: "1px solid #dee2e6",
                            borderRadius: "5px",
                            boxShadow: activeTab === order.id ? "2px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
                        }}
                    >
                        {order.buildingName}
                    </div>
                </li>
            ))}
        </ul>

        <div className="table-responsive" style={{ width: '100%' }}>
            <div className="row border font-weight-bold mx-1">
                <div className="col-md-2 p-2">ID de pieza</div>
                <div className="col-md-3 p-2">Nombre de pieza</div>
                <div className="col-md-2 p-2">Totales</div>
                <div className="col-md-2 p-2">En taller</div>
                <div className="col-md-2 p-2">En obra</div>
                <div className="col-md-1 p-2"></div>
            </div>

            {filteredData.length === 0 ? (
                <div className="col-md-12 text-center h5 mt-2">No hay resultados</div>
            ) : (
                <div style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}>
                    {filteredData.map((inventory, idx) => {
                        const part = inventory.part;
                        return (
                            <div
                                className="row border mx-1 text-reset"
                                key={idx}
                                style={{
                                    background: idx % 2 === 0 ? "#fff" : "#f5f5f5",
                                }}
                            >
                                <div className="col-md-2 p-2">
                                    {part.partId}
                                    <div>
                                        {part.isManual ? (
                                            <span
                                                className="badge btn btn-soft-dark"
                                                style={{ color: "#000 !important" }}
                                            >
                                                Manual
                                            </span>
                                        ) : (
                                            <span
                                                className="badge btn btn-soft-success"
                                                style={{ color: "#28A745" }}
                                            >
                                                Precargada
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-3 p-2">{part.name}</div>
                                <div className="col-md-2 p-2">{inventory.totalParts}</div>
                                <div className="col-md-2 p-2">{inventory.totalParts - inventory.inUse}</div>
                                <div className="col-md-2 p-2">{inventory.inUse}</div>
                                <div className="col-md-1 p-2 d-flex align-items-center">
                                    <Link to={{ pathname: `/edit/${inventory.id}` }} className="text-warning ml-2">
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                    <Link to={{ pathname: `/details/${inventory.id}` }} className="text-primary ml-2">
                                        <i className="fas fa-tools"></i>
                                    </Link>
                                    {part.isManual && (
                                        <i
                                            className="fas fa-trash text-danger ml-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleDelete(inventory.id)}
                                        ></i>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    </div>
);

InventoryReportView.propTypes = {
    filteredData: PropTypes.array.isRequired,
    activeTab: PropTypes.string.isRequired,
    handleTabClick: PropTypes.func.isRequired,
    orders: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

export default InventoryReportView;
