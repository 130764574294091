import React from "react";
import { BrowserRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PreventiveMaintenanceListDone from "components/preventive_maintenances/done/List";
import PreventiveMaintenanceListPending from "components/preventive_maintenances/pending/List";
import { ModalRoute } from "seed/helpers";
import MaintenanceCreate from "components/preventive_maintenances/FormSave";
import MaintenanceDetails from "components/preventive_maintenances/done/Details";
import MaintenanceAssign from "components/preventive_maintenances/pending/FormAssign";
import MaintenanceReassign from "components/preventive_maintenances/pending/FormSet";
import { Typeahead } from "react-bootstrap-typeahead";
import uniqBy from "lodash/uniqBy";

const PreventiveMaintenances = ({
    searchRef,
    search,
    handleChange,
    cranes,
    orders,
    pathname,
    searchBy,
    maintenances,
    assignedCount,
    expiredCount,
    handleSearchByChange,
}) =>
    <BrowserRouter basename={pathname}>
        <div class="content container-fluid p-7">
            <div class="page-header pt-4">
                <div class="row align-items-end">
                    <div class="col-sm">
                        <h1 class="page-header-title">Mantenimientos Preventivos</h1>
                    </div>
                    <div class="col-sm-auto">
                        <div class="btn-group" role="group">
                            <Link to="/create" className="btn btn-primary mx-3">
                                <i class="tio-add mr-1"></i>Indicar mantenimiento manualmente
                            </Link>
                        </div>
                        <div class="btn-group dropdown ml-1">
                            <button class="dropdown-toggle-no-caret" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-expanded="false" style={{ border: "none", backgroundColor: "white" }}>
                                <i class="fas fa-ellipsis-v fa-lg"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item"
                                    href="https://docs.google.com/spreadsheets/d/1QzuwoXVD8xavZyiIZzw1No-SOGKGBOy4-H_ATF31iI4/edit?usp=sharing">
                                    <i class="fas fa-bug" /> Reporte de incidencias técnicas
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-3 mx-1">
                <div className="row">
                    <div className="col-md-6">
                        <h4>
                            {pathname === "/preventive_maintenance/done" ? "Realizados" : "Pendientes de realizar"}
                        </h4>
                        {pathname !== "/preventive_maintenance/done" && (
                            <div className="d-flex flex-row mt-2">
                                <span className="badge badge-primary mr-3">
                                    Asignados: {assignedCount}
                                </span>
                                <span className="badge badge-danger">
                                    Vencidos: {expiredCount}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-row justify-content-end align-items-center">
                            <div className="form-group mr-3">
                                <label htmlFor="searchBySelect">Buscar por:</label>
                                <select
                                    id="searchBySelect"
                                    className="form-control"
                                    value={searchBy}
                                    onChange={handleSearchByChange}
                                >
                                    <option value="Grua">Grúa</option>
                                    <option value="Obra">Obra</option>
                                    {pathname === "/preventive_maintenance/done" &&
                                        <option value="Folio">Folio</option>
                                    }
                                    <option value="Tecnico">Técnico</option>
                                </select>
                            </div>
                            <div className="">
                                {searchBy === "Grua" ? (
                                    <Typeahead
                                        id="menu"
                                        ref={searchRef}
                                        placeholder={`Buscar ${searchBy}`}
                                        onInputChange={handleChange}
                                        labelKey={(crane) => `${crane.series} - ${crane.model} ${crane.number}`}
                                        onChange={(selected) => (selected.length > 0 ? handleChange(selected[0].series) : "")}
                                        options={[
                                            ...cranes
                                        ]}
                                    >
                                        <div className="rbt-aux">
                                            {search.length === 0 && <i className="fa fa-search rbt-aux"></i>}
                                            {search.length > 0 && (
                                                <i
                                                    className="fa fa-times rbt-close text-danger"
                                                    style={{ paddingTop: "4px" }}
                                                    role="button"
                                                    onClick={() => {
                                                        searchRef.current.clear();
                                                        handleChange("");
                                                    }}
                                                ></i>
                                            )}
                                        </div>
                                    </Typeahead>
                                ) : null}
                                {searchBy === "Obra" ? (
                                    <Typeahead
                                        id="menu"
                                        ref={searchRef}
                                        placeholder={`Buscar ${searchBy}`}
                                        onInputChange={handleChange}
                                        labelKey={(order) => `${order.buildingName}`}
                                        onChange={(selected) => (selected.length > 0 ? handleChange(selected[0].buildingName) : "")}
                                        options={uniqBy(orders, "buildingName")}
                                    >
                                        <div className="rbt-aux">
                                            {search.length === 0 && <i className="fa fa-search rbt-aux"></i>}
                                            {search.length > 0 && (
                                                <i
                                                    className="fa fa-times rbt-close text-danger"
                                                    style={{ paddingTop: "4px" }}
                                                    role="button"
                                                    onClick={() => {
                                                        searchRef.current.clear();
                                                        handleChange("");
                                                    }}
                                                ></i>
                                            )}
                                        </div>
                                    </Typeahead>
                                ) : null}
                                {searchBy === "Folio" && pathname === "/preventive_maintenance/done" ? (
                                    <Typeahead
                                        id="menu"
                                        ref={searchRef}
                                        placeholder={`Buscar ${searchBy}`}
                                        onInputChange={handleChange}
                                        labelKey={(maintenance) => `${maintenance.id}`}
                                        onChange={(selected) => (selected.length > 0 ? handleChange(selected[0].id) : "")}
                                        options={[
                                            ...maintenances.filter(maintenance => maintenance.status == "APPROVED" || maintenance.status == "FINISHED")
                                        ]}
                                    >
                                        <div className="rbt-aux">
                                            {search.length === 0 && <i className="fa fa-search rbt-aux"></i>}
                                            {search.length > 0 && (
                                                <i
                                                    className="fa fa-times rbt-close text-danger"
                                                    style={{ paddingTop: "4px" }}
                                                    role="button"
                                                    onClick={() => {
                                                        searchRef.current.clear();
                                                        handleChange("");
                                                    }}
                                                ></i>
                                            )}
                                        </div>
                                    </Typeahead>
                                ) : null}
                                {searchBy === "Tecnico" ? (
                                    <Typeahead
                                        id="menu"
                                        ref={searchRef}
                                        placeholder={`Buscar ${searchBy}`}
                                        onInputChange={handleChange}
                                        labelKey={(maintenance) => `${maintenance?.technician?.firstName}`}
                                        onChange={(selected) => (selected.length > 0 ? handleChange(selected[0].technician?.firstName) : "")}
                                        options={uniqBy(maintenances?.filter(maintenance => maintenance?.technician), maintenance => maintenance?.technician?.id)}
                                    >
                                        <div className="rbt-aux">
                                            {search.length === 0 && <i className="fa fa-search rbt-aux"></i>}
                                            {search.length > 0 && (
                                                <i
                                                    className="fa fa-times rbt-close text-danger"
                                                    style={{ paddingTop: "4px" }}
                                                    role="button"
                                                    onClick={() => {
                                                        searchRef.current.clear();
                                                        handleChange("");
                                                    }}
                                                ></i>
                                            )}
                                        </div>
                                    </Typeahead>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {pathname === "/preventive_maintenance/done" ?
                <PreventiveMaintenanceListDone
                    search={search}
                    pathName={pathname}
                    searchBy={searchBy}
                />
                :
                <PreventiveMaintenanceListPending
                    search={search}
                    pathName={pathname}
                />
            }

        </div>

        <ModalRoute
            path="/create"
            component={MaintenanceCreate}
        />

        <ModalRoute
            path="/:preventiveMaintenanceId(\d+)"
            width={750}
            component={MaintenanceDetails}
        />

        <ModalRoute
            path="/assign/:preventiveMaintenanceId(\d+)"
            width={650}
            component={MaintenanceAssign}
        />

        <ModalRoute
            path="/reassign/:preventiveMaintenanceId(\d+)"
            width={650}
            component={MaintenanceReassign}
        />

        <ModalRoute
            path="/:preventiveMaintenanceId(\d+)/edit"
            component={MaintenanceCreate}
        />

    </BrowserRouter>;

PreventiveMaintenances.propTypes = {
    pathname: PropTypes.string
};

export default PreventiveMaintenances;
