import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import View from "components/inventory/admin/InventoryReportBuilding.view";
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import swal from "sweetalert";

const InventoryReportBuilding = ({ search }) => {
    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState("TODOS");
    const [filteredData, setFilteredData] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);

    const qActiveOrders = useQuery(`{
        orders {
            id
            buildingName
            status
            crane {
                model
                series
            }
            shippings {
                contents {
                    partId
                    partName
                }
            }
        }
    }`, "status=IN_BUILDING OR status=TRAVELING");

    const qInventories = useQuery(`{
        inventories {
            id
            totalParts
            inUse
            category
            parts {
                id
                name
                partId
                isManual
                craneModel
                inventoryExceptions {
                    status
                }
            }
        }
    }`);

    const [callDelete] = usePost("/inventories/delete_inventory", {
        onCompleted: () => {
            swal("¡Éxito!", "Inventario eliminado exitosamente.", "success").then(() => {
                window.location.reload();
            });
        },
        onError: () => {
            swal("¡Error!", "Error al eliminar el inventario.", "error");
        }
    });

    const handleDelete = (inventoryId) => {
        swal({
            title: "¿Estás seguro?",
            text: "Esta acción eliminará el inventario y todas las piezas asociadas.",
            icon: "warning",
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                callDelete({ inventory_id: inventoryId });
                setFilteredData(filteredData.filter(inventory => inventory.id !== inventoryId));
            }
        });
    };

    useEffect(() => {
        if (qActiveOrders.data?.orders) {
            const allOrders = [{ id: "TODOS", buildingName: "Todos" }, ...qActiveOrders.data.orders];
            setOrders(allOrders);
        }
    }, [qActiveOrders.data]);

    useEffect(() => {
        if (search) {
            const matchingOrders = qActiveOrders.data?.orders?.filter(order =>
                order.buildingName.toLowerCase().includes(search.toLowerCase())
            ) || [];

            setFilteredOrders([{ id: "TODOS", buildingName: "Todos" }, ...matchingOrders]);
        } else {
            setFilteredOrders(orders);
        }
    }, [search, qActiveOrders.data, orders]);

    useEffect(() => {
        if (activeTab === "TODOS") {
            setFilteredData(qInventories.data?.inventories.map(inventory => ({
                ...inventory,
                part: inventory.parts?.[0] || null, 
            })) || []);
        } else {
            const selectedOrder = qActiveOrders.data?.orders?.find(order => order.id === activeTab);

            if (selectedOrder && selectedOrder.shippings?.length > 0) {
                const partsInOrder = selectedOrder.shippings.flatMap(
                    shipping => shipping.contents?.map(content => content.partId) || []
                );

                const filteredInventory = qInventories.data?.inventories?.map(inventory => {
                    const matchingPart = inventory.parts?.find(part =>
                        partsInOrder.includes(part.partId) &&
                        part.craneModel === selectedOrder.crane.model
                    );

                    return matchingPart ? { ...inventory, part: matchingPart } : null;
                }).filter(item => item !== null);

                setFilteredData(filteredInventory || []);
            } else {
                setFilteredData([]);
            }
        }
    }, [activeTab, qActiveOrders.data, qInventories.data]);

    if (qActiveOrders.loading || qInventories.loading) return <Loading />;
    if (qActiveOrders.error || qInventories.error) return "Error";

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <View 
            orders={filteredOrders} 
            filteredData={filteredData} 
            activeTab={activeTab} 
            handleTabClick={handleTabClick} 
            handleDelete={handleDelete} 
        />
    );
};

InventoryReportBuilding.propTypes = {
    search: PropTypes.string,
};

export default InventoryReportBuilding;
