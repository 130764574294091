import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "styles/css/ScrollBar.css";

const InventoryReportView = ({
    filteredData,
    activeTab,
    activeModel,
    handleTabClick,
    handleModelClick,
    categories,
    models,
    handleDelete
}) => (
    <div className="container mt-4">
        <div className="d-flex">
            <ul 
                className="nav flex-column nav-pills mr-3" 
                style={{ 
                    width: '200px', 
                    maxHeight: '60vh',
                    overflowY: 'auto', 
                    overflowX: 'hidden', 
                    display: 'block',   
                    padding: '0',       
                    listStyle: 'none',  
                }}
            >
                {categories.map((category, index) => (
                    <li 
                        className="nav-item" 
                        key={index} 
                    >
                        <div
                            onClick={() => handleTabClick(category)}
                            style={{
                                cursor: 'pointer',
                                width: '180px',
                                backgroundColor: activeTab === category ? "#007bff" : "#f8f9fa",
                                color: activeTab === category ? "#fff" : "#000",
                                textAlign: 'center',
                                padding: '10px',
                                fontWeight: activeTab === category ? "bold" : "normal",
                                marginBottom: '5px',
                                border: "1px solid #dee2e6",
                                borderRadius: "5px",
                                boxShadow: activeTab === category ? "2px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
                                whiteSpace: 'normal'  
                            }}
                        >
                            {category}
                        </div>
                    </li>
                ))}
            </ul>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div className="scroll-horizontal" style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginBottom: '10px' }}>
                    <ul 
                        className="nav nav-tabs mb-3" 
                        style={{ 
                            display: 'flex', 
                            justifyContent: 'space-around', 
                            padding: '0',       
                            listStyle: 'none',
                            flexWrap: 'wrap' 
                        }}>
                        {models.map((modelGroup) => (
                            <li className="nav-item" key={modelGroup} style={{ flex: '1 1 auto' }}>
                                <div
                                    onClick={() => handleModelClick(modelGroup)}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: activeModel === modelGroup ? "#007bff" : "#f8f9fa",
                                        color: activeModel === modelGroup ? "#fff" : "#000",
                                        padding: '10px',
                                        fontWeight: activeModel === modelGroup ? "bold" : "normal",
                                        border: "1px solid #dee2e6",
                                        borderRadius: "5px",
                                        margin: '0 5px',
                                        boxShadow: activeModel === modelGroup ? "2px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
                                        textAlign: 'center',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {modelGroup}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Tabla de resultados */}
                <div className="table-responsive" style={{ width: '98%' }}> 
                    <div className="row border font-weight-bold mx-1">
                        <div className="col-md-2 p-2">ID de pieza</div>
                        <div className="col-md-3 p-2">Nombre de pieza</div>
                        <div className="col-md-2 p-2">Totales</div>
                        <div className="col-md-2 p-2">En taller</div>
                        <div className="col-md-2 p-2">En obra</div>
                        <div className="col-md-1 p-2"></div>
                    </div>

                    {filteredData.length === 0 ? (
                        <div className="col-md-12 text-center h5 mt-2"> No hay resultados </div>
                    ) : (
                        <div style={{ height: "60vh", overflowY: "auto" }}>
                            {filteredData.map((item, idx) => (
                                <div
                                    className="row border mx-1 text-reset"
                                    key={idx}
                                    style={{
                                        background: idx % 2 === 0 ? "#fff" : "#f5f5f5",
                                    }}
                                >
                                    <div className="col-md-2 p-2">
                                        {item?.parts[0]?.partId}
                                        <div>
                                            {item?.parts[0]?.isManual ? (
                                                <span
                                                    className="badge btn btn-soft-dark" style={{ color: "#000 !important" }}
                                                >
                                                    Manual
                                                </span>
                                            ) : (
                                                <span
                                                    className="badge btn btn-soft-success" style={{ color: "#28A745" }}
                                                >
                                                    Precargada
                                                </span>
                                            )}
                                        </div>

                                        {item.part.inventoryExceptions && item.part.inventoryExceptions.some(exception => exception.status === "ACTIVE") && (
                                            <div>
                                                <span className="badge btn btn-soft-danger" style={{ color: "#EC573B" }}>Con excepciones</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-3 p-2">{item.part.name}</div>
                                    <div className="col-md-2 p-2">{item.totalParts}</div>
                                    <div className="col-md-2 p-2">{item.totalParts - item.inUse}</div>
                                    <div className="col-md-2 p-2">{item.inUse}</div>
                                    <div className="col-md-1 p-2">
                                        <Link to={{ pathname: `/edit/${item.id}` }} className="text-warning ml-2">
                                            <i className="fa fa-edit"></i>
                                        </Link>
                                        <Link to={{ pathname: `/details/${item.id}` }} className="text-primary ml-2">
                                            <i className="fas fa-tools"></i>
                                        </Link>
                                        {item.part.isManual && (
                                            <i className="fas fa-trash text-danger ml-2" 
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleDelete(item.id)}></i>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);

InventoryReportView.propTypes = {
    filteredData: PropTypes.array.isRequired,
    activeTab: PropTypes.string.isRequired,
    activeModel: PropTypes.string,
    handleTabClick: PropTypes.func.isRequired,
    handleModelClick: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired,
    models: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired, 
};

export default InventoryReportView;
