import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/shippings/FormItem.view";
import { useQuery } from "seed/gql";


const FormItemSave = ({ order, setHideModal, selectedParts, setSelectedParts }) => {

  const qParts = useQuery(`{ 
    parts{
      name
      partId
      craneModel
    }
  }`, "crane_model = " + order.crane.model + " AND is_supervision = False");

  const { parts = [] } = qParts.data;

  const onSubmit = (values) => {
    
    if(!values.part){
      alert("Selecciona una pieza")
      return
    }
    
    setSelectedParts([...selectedParts, {
      ...values,
      partName: values.part.name,
      partId: values.part.partId,
      images: [],
      suggestionId: -1,
      manual: true,
      elevationNumber: 0,
    }]);
    setHideModal(true);
  }

  return <View 
    parts={parts}
    onSubmit={onSubmit}
    setHideModal={setHideModal}
  />;    
  
}

FormItemSave.propTypes = {
  setHideModal: PropTypes.func.isRequired,
  selectedParts: PropTypes.array.isRequired,
  setSelectedParts: PropTypes.func.isRequired
}
    
export default FormItemSave;